import { Controller as BaseController } from '@hotwired/stimulus';
import { navigator } from "@hotwired/turbo";
import { useFileUpload } from '../../fileUpload';

class Controller extends BaseController {
  static targets = [
    'form',
    'allowDraftInput',
    'input',
    'submitButton',
    'fileInput',
    'fileUpload'
  ];

  connect() {
    useFileUpload(this);
    this.connectFileUpload();
  }

  disconnect() {
    this.disconnectFileUpload();
  }

  validateTimeSlots() {
    const timeSlotInputs = this.formTarget.querySelectorAll('input[type="time"][data-add-on-type-select-target="timeslotInput"]');
    if (timeSlotInputs.length === 0) return true;

    console.log('Time slot inputs:', Array.from(timeSlotInputs).map(input => {
      return {
        name: input.name,
        value: input.value,
        disabled: input.disabled
      }
    }));

    let isValid = true;
    timeSlotInputs.forEach(input => {
      if (!input.disabled) {
        if (!input.value || input.value.trim() === '') {
          input.classList.add('error');
          input.nextElementSibling?.classList.remove('hidden');
          isValid = false;
          console.log('Invalid input:', input.name);
        } else {
          input.classList.remove('error');
          input.nextElementSibling?.classList.add('hidden');
        }
      }
    });

    console.log('Form validation result:', isValid);
    return isValid;
  }

  submitForm(event) {
    if (document.activeElement) {
      document.activeElement.blur();
    }

    // 檢查時間選擇器
    if (!this.validateTimeSlots()) {
      event.preventDefault();
      this.showFlashMessage('Each time slots cannot be blank');
      return;
    }

    this.allowDraftInputTarget.value = false;
    getApplicationController(this.application).startAction();
    navigator.submitForm(this.formTarget);
  }

  showFlashMessage(message) {
    // 移除現有的 flash messages
    document.querySelectorAll('.flash-message').forEach(el => el.remove());

    // 創建新的 flash message container
    const flashDiv = document.createElement('div');
    flashDiv.className = 'flash-message fixed inset-0 flex flex-col gap-2 items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start';
    flashDiv.style.zIndex = '99999';
    
    // 使用與系統相同的 HTML 結構
    flashDiv.innerHTML = `
      <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black/5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div id="alert-icon" class="flex-shrink-0">
                <i class="far fa-exclamation-circle text-yellow-900" data-alert-target="icon"></i>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p id="alert-title" class="text-sm font-medium text-gray-1" data-alert-target="content">
                  ${message}
                </p>
                <p id="alert-detail" class="mt-1 text-sm text-gray-2"></p>
              </div>
              <div class="ml-4 h-6 flex-shrink-0 flex items-center">
                <button class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <i class="fal fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

    // 插入到 body 的最後
    document.body.appendChild(flashDiv);

    // 添加關閉按鈕功能
    const closeButton = flashDiv.querySelector('button');
    if (closeButton) {
      closeButton.addEventListener('click', () => flashDiv.remove());
    }

    // 自動消失（可選）
    setTimeout(() => {
      flashDiv.remove();
    }, 5000);
  }
}

export default application => application.register('admin-add_ons-edit', Controller);
