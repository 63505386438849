import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['timeslot', 'start', 'end', 'row'];

  connect() {
    this.standardizeButtonStyles();
  }

  standardizeButtonStyles() {
    this.element.querySelectorAll('.btn-secondary').forEach(button => {
      button.classList.add('w-auto');
    });
  }

  buildQueryURL() {
    const url = new URL(this.data.get("url"));
    const rowCount = this.timeslotTarget.children.length;
    const baseStartName = 'request[available_time_slots][0][start_time]';
    const baseEndName = 'request[available_time_slots][0][end_time]';
    const startName = this.hasStartTarget ? 
      this.startTarget.name.replace(/\[\d+\]/, `[${rowCount}]`) : 
      baseStartName.replace('[0]', `[${rowCount}]`);
    const endName = this.hasEndTarget ? 
      this.endTarget.name.replace(/\[\d+\]/, `[${rowCount}]`) : 
      baseEndName.replace('[0]', `[${rowCount}]`);
    url.searchParams.set('start', startName);
    url.searchParams.set('end', endName);
    url.searchParams.set('target', this.timeslotTarget.id);
    url.pathname = `${url.pathname}.turbo_stream`;
    return url.toString();
  }

  add(event) {
    event.preventDefault();
    const url = this.buildQueryURL();
    if (!url) return;

    Turbo.visit(url, {
      action: 'append',
      target: this.timeslotTarget.id
    });
  }

  remove(event) {
    event.preventDefault();
    event.stopPropagation();
    
    const row = event.target.closest('[data-time-slot-target="row"]');
    if (row) {
      row.style.transition = 'opacity 0.2s';
      row.style.opacity = '0';
      
      setTimeout(() => {
        row.remove();
      }, 200);
    }
  }
}
